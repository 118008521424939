import React, { useCallback, useMemo, useState } from 'react';
import { IDataItem, IRelatedMessage } from '@cpa/base-core/types';
import * as _ from 'lodash';
import { executeBackendAction } from '@cpa/base-core/helpers';
import { axiosDictionary } from '@cpa/base-core/api';
import { IColumn } from '@fluentui/react';

import MessageItem from './components/MessageItem/MessageItem';
import styles from './MessageContainer.module.scss';
import MessageInput from './components/MessageInput/MessageInput';

interface IMessageContainerProps {
  parentMessage: IDataItem;
  childItems: IDataItem[];
  isFetching: boolean;
  totalItems: number;
  pageIdentifier: string;
  lastRead?: string;
  columns: IColumn[];
}

const MessageContainer: React.FC<IMessageContainerProps> = ({
  columns,
  parentMessage,
  childItems,
  pageIdentifier,
  lastRead,
  totalItems,
  isFetching,
}) => {
  const [relatedMessage, setRelatedMessage] = useState<IRelatedMessage | null>(null);

  const onMessageReply = useCallback((relatedUserMessage: IRelatedMessage) => {
    setRelatedMessage(relatedUserMessage);
  }, []);

  const onMessageInView = useCallback(
    _.debounce(async (messageIdentifier: string) => {
      console.log(
        'read',
        childItems.find((item) => item.identifier === messageIdentifier)
      );
      await executeBackendAction(axiosDictionary.appDataService, pageIdentifier, 'read-message', [messageIdentifier]);
    }, 1000),
    [childItems, pageIdentifier]
  );

  const messages = useMemo(() => {
    let readMessageProcessed = false;
    return childItems.map((message, index) => {
      if (!readMessageProcessed && lastRead) {
        readMessageProcessed = message.identifier === lastRead;
      }
      const isMessageRead = !lastRead ? false : !readMessageProcessed;
      return (
        <MessageItem
          key={index}
          message={message}
          onMessageReply={onMessageReply}
          onMessageInView={onMessageInView}
          isRead={isMessageRead}
          isFetching={isFetching}
        />
      );
    });
  }, [isFetching, childItems, lastRead, onMessageInView, onMessageReply]);

  const unsetRelatedMessage = useCallback(() => {
    setRelatedMessage(null);
  }, []);

  const childrenWidth = useMemo(() => {
    const column = columns.find((col) => col.key === '__simpleCard');
    if (column) return column.calculatedWidth;
    return undefined;
  }, [columns]);

  return (
    <div className={styles.container} style={{ width: childrenWidth }}>
      <div>{messages}</div>
      <div>
        <MessageInput parentMessage={parentMessage} relatedMessage={relatedMessage} unsetRelatedMessage={unsetRelatedMessage} />
      </div>
    </div>
  );
};

export default MessageContainer;
