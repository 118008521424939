import { Schemas } from '@cp/base-types';
import { Environment } from '@cpa/base-core/app/environment';
import { generateJourneyUrl, signIn } from '@cpa/base-core/helpers';
import { IGlobalState, store } from '@cpa/base-core/store';
import { openSettings } from '@cpa/base-core/store/settings/actions';
import { MatchedCpa } from '@cpa/base-core/types';
import { Callout, Checkbox, CommandBarButton, DefaultButton, DirectionalHint, ICalloutProps, Icon, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { CSSProperties, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { match } from 'react-router';
import { Link } from 'react-router-dom';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';
import HtmlContent from '../../../HtmlContent/HtmlContent';
import { HIDE_SIGNIN_STORAGE_KEY, PageContext } from '../../Layout';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import ProfilePopup from '../ProfilePopup/ProfilePopup';

import styles from './Header.module.scss';
import SearchBox from './components/SearchBox/SearchBox';
import UserCalloutContainer from './components/UserCalloutContainer';

export interface IHeaderButton {
  key: string;
  tooltip: string;
  icon: string;
  container?: React.ComponentType<ICalloutProps>;
  element?: (setPopupOpened: (state: boolean) => void, visible: boolean) => ReactElement;
  onClick?: (key: string) => void;
  style?: CSSProperties;
  calloutStyle?: CSSProperties;
  calloutClassname?: string;
  layerClassName?: string;
  mobileStyle?: CSSProperties;
}

export interface IHeaderProps {
  isMenuOpen: boolean;
  toggleMainMenu: () => void;
  page?: Schemas.CpaPage;
  logo?: string;
  logoPath?: string;
  onTitleClick?: () => void;
  buttons: IHeaderButton[];
  appTitle?: string;
  hideMenuButton?: boolean;
  hasForcedLanguage?: boolean;
  routeMatch?: match;
}

const baseCalloutProps: Partial<ICalloutProps> = {
  style: { padding: '10px 10px 10px 10px' },
  role: 'dialog',
  gapSpace: 0,
  isBeakVisible: true,
  beakWidth: 10,
  directionalHint: DirectionalHint.topAutoEdge,
  setInitialFocus: false,
  preventDismissOnScroll: true,
  preventDismissOnResize: true,
  preventDismissOnLostFocus: false,
};

const IGNORED_TOUR_BUBBLES = ['Profile', 'SignIn'];

const Header: React.FC<IHeaderProps> = ({
  hideMenuButton,
  isMenuOpen,
  toggleMainMenu,
  page,
  logo,
  logoPath,
  buttons,
  onTitleClick,
  appTitle,
  hasForcedLanguage = false,
  routeMatch,
}) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const currentTourBubble = useSelector((state: IGlobalState) => state.app.currentTourBubble);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const desktopOnly = useMediaQuery({ query: '(min-width: 900px)' });
  const [calloutsStates, setCalloutStates] = useState<{ [name: string]: boolean }>({});
  const [signInHintHidden, setSignInHintHidden] = useState(false);

  const pageContext = useContext(PageContext);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const onCalloutDismiss = useCallback(() => {
    setCalloutStates({});
  }, []);

  const onButtonClick = useCallback(
    (name: string, handler: IHeaderButton['onClick']) => (): void => {
      setCalloutStates({
        ...calloutsStates,
        [name]: !calloutsStates[name],
      });
      if (handler) {
        handler(name);
      }
    },
    [calloutsStates]
  );

  const setPopupState = useCallback(
    (popup: string) =>
      (state: boolean): void => {
        setCalloutStates({
          ...calloutsStates,
          [popup]: state,
        });
      },
    [calloutsStates]
  );

  const wsConnected = useSelector((state: IGlobalState) => state.websocket.connected);
  const myCosmosRef = useRef<HTMLDivElement>(null);
  const [myCosmosCalloutOpen, { setFalse: closeMyCosmosCallout, toggle: toggleMyCosmosCallout }] = useBoolean(false);

  useEffect(() => {
    // Close callout on page change. Useful when user clicks on Edit profile button.
    closeMyCosmosCallout();
  }, [closeMyCosmosCallout, page]);

  const [t, i18n] = useTranslation();
  const locales = useSelector((state: IGlobalState) => state.app.locales);
  const languageRef = useRef<HTMLDivElement>(null);
  const [languageCalloutOpen, { setFalse: closeLanguageCallout, toggle: toggleLanguageCallout }] = useBoolean(false);
  const onAppLanguageSelect = useCallback(
    (code: string): void => {
      if (hasForcedLanguage && routeMatch && 'locale' in routeMatch.params) {
        const urlWithoutLocale = routeMatch.url
          .split('/')
          .filter((part) => part !== (routeMatch.params as { locale: string })['locale'])
          .join('/');
        dispatch(push(urlWithoutLocale));
      }
      i18n.changeLanguage(code);
      closeLanguageCallout();
    },
    [closeLanguageCallout, dispatch, hasForcedLanguage, i18n, routeMatch]
  );

  const user = useSelector((state: IGlobalState) => state.auth.user);
  const configuration = useSelector((state: IGlobalState) => state.app.cpa?.configuration) as MatchedCpa['configuration'];
  const userProfileLabel = useSelector((state: IGlobalState) => state.app.cpa?.userProfileLabel);
  const profilePopup = useMemo(() => {
    if (!user?.account) {
      return <></>;
    }

    const vendors =
      user?.companies.map((company) => company.accounts.filter((account) => account.type === 'Vendor').map((account) => account.no)).flat() || [];
    const customers =
      user?.companies.map((company) => company.accounts.filter((account) => account.type === 'Customer').map((account) => account.no)).flat() || [];

    let rolesLabel = '';
    if (vendors.length) {
      rolesLabel += t('roles.vendor', { nos: vendors.join(', ') });
      rolesLabel += customers.length ? '\n' : '';
    }
    if (customers.length) {
      rolesLabel += t('roles.customer', { nos: customers.join(', ') });
    }

    return (
      <ProfilePopup
        darkMode={darkMode}
        profile={{
          imageUrl: '',
          text: user?.account.name,
          secondaryText: rolesLabel,
        }}
        t={t}
        passwordResetLink={generateJourneyUrl(configuration.msalAuthJourneyReset, configuration.msalAuthAuthority)}
        linkAccountLink={generateJourneyUrl(configuration.msalAuthJourneyLinkAccount, configuration.msalAuthAuthority)}
      />
    );
  }, [user, darkMode, t, configuration]);

  const currentLanguageName = useMemo(
    () => locales.find(({ identifier }) => identifier === i18n.language)?.alternateName || i18n.language,
    [i18n.language, locales]
  );

  const openSettingsPanel = useCallback(() => {
    localStorage.setItem('hideSettingsBubble', 'true');
    dispatch(openSettings());
  }, [dispatch]);

  const onSignInCalloutClick = useCallback(async () => {
    const state = store.getState();
    const msalProvider = state.app.msalProvider;
    const configuration = state.app.cpa?.configuration;
    const currentLocation = state.router.location;

    if (!msalProvider) {
      console.warn(`Failed to start signin. Msal is not initialized.`);
      return;
    }

    if (!configuration) return;

    console.debug(`Executing signin`);
    await signIn(msalProvider, configuration.msalAuthScopes ?? [], currentLocation);
    pageContext?.setIsSignInHintCancelled(true);
  }, [pageContext]);

  const handleSignInCheckboxChange = useCallback(() => {
    localStorage.setItem(HIDE_SIGNIN_STORAGE_KEY, `${!signInHintHidden}`);
    setSignInHintHidden(!signInHintHidden);
  }, [signInHintHidden]);

  return (
    <>
      {!user && !pageContext?.isSignInHintCancelled && !IGNORED_TOUR_BUBBLES.includes(currentTourBubble?.step || '') && (
        <Callout
          target={myCosmosRef}
          role={'dialog'}
          gapSpace={0}
          isBeakVisible={true}
          beakWidth={10}
          calloutMaxHeight={undefined}
          className={classNames({ [styles.signInBubble]: !darkMode }, { [styles.signInBubbleDark]: darkMode })}
        >
          <h3>{t('header.controls.signin.bubble.title')}</h3>
          <p>{t('header.controls.signin.bubble.text')}</p>
          <Checkbox
            label={t('header.controls.doNotShowAgain')}
            onChange={handleSignInCheckboxChange}
            checked={signInHintHidden}
            className={styles.checkbox}
          />
          <div className={styles.bubbleButtons}>
            <DefaultButton onClick={() => pageContext?.setIsSignInHintCancelled(true)} className={darkMode ? styles.cancelBtnDark : styles.cancelBtn}>
              {t('feedback.bubble.cancelBtn')}
            </DefaultButton>
            <PrimaryButton
              label={t('header.controls.signin.bubble.title')}
              onClick={onSignInCalloutClick}
              className={darkMode ? styles.primaryBtnDark : styles.primaryBtn}
            >
              {t('header.controls.signin.bubble.title')}
            </PrimaryButton>
          </div>
        </Callout>
      )}
      <div className={styles.topBar}>
        <div ref={myCosmosRef} id={'profile'} className={styles.linkWrapper} onClick={toggleMyCosmosCallout}>
          <Icon iconName={'CircleUserSingleColor'} className={styles.globe} />
          {`${t('header.controls.user.my')} ${userProfileLabel}`}
          <Icon iconName={myCosmosCalloutOpen ? 'ChevronUp' : 'ChevronDown'} className={styles.chevron} />
          <UserCalloutContainer {...baseCalloutProps} hidden={!myCosmosCalloutOpen} onDismiss={closeMyCosmosCallout} target={myCosmosRef}>
            {profilePopup}
          </UserCalloutContainer>
        </div>
        {locales.length > 1 && (
          <div ref={languageRef} className={styles.linkWrapper} onClick={toggleLanguageCallout}>
            <Icon iconName={'GlobeSingleColor'} className={styles.globe} />
            <span>{currentLanguageName}</span>
            <Icon iconName={languageCalloutOpen ? 'ChevronUp' : 'ChevronDown'} className={styles.chevron} />
            <Callout {...baseCalloutProps} hidden={!languageCalloutOpen} onDismiss={closeLanguageCallout} target={languageRef}>
              <LanguageSelect
                darkMode={darkMode}
                onLanguageClick={onAppLanguageSelect}
                languages={locales}
                title={t('header.controls.language.title')}
                currentLanguage={i18n.language}
              />
            </Callout>
          </div>
        )}
        {/*{user ? <MessageCount /> : null}*/}
        <HoverTooltip content={t('header.controls.settings.appSettings')}>
          <div className={styles.linkWrapper} onClick={openSettingsPanel}>
            <Icon iconName={'Settings'} className={styles.globe} />
          </div>
        </HoverTooltip>
      </div>
      <div className={classNames(styles.header, { [styles.dark]: darkMode })}>
        {Environment.env.REACT_APP_DISPLAY_WEBSOCKET_STATUS_LINE === 'true' && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              height: 1,
              backgroundColor: wsConnected ? '#5b915b' : '#a42f67',
            }}
          />
        )}
        {!isMobileMenuOpen && (
          <div className={styles.labels}>
            {!hideMenuButton && (
              <div>
                <div data-testid="MainMenuToggleButton" onClick={toggleMainMenu} aria-label={'nav action'} className={styles.closeButtonWrapper}>
                  <div className={classNames(styles.closeButton, isMenuOpen ? styles.active : styles.notActive)}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            )}
            <span className={styles.title} onClick={onTitleClick}>
              {appTitle ? (
                logoPath ? (
                  <Link className={styles.title} style={{ textDecoration: 'none' }} to={logoPath}>
                    <HtmlContent style={{ display: 'inline' }} className={styles.default} html={appTitle} translate="no" />
                  </Link>
                ) : (
                  <HtmlContent style={{ display: 'inline' }} className={styles.default} html={appTitle} translate="no" />
                )
              ) : (
                <span className={styles.default}>Unknown CPA</span>
              )}
            </span>
            {desktopOnly && page?.name && (
              <div className={styles.currentPage}>
                <span className={styles.divider} />
                <span className={styles.text}>{page.name}</span>
              </div>
            )}
          </div>
        )}

        {!isMobileMenuOpen && (
          <div className={styles.logo}>
            {!!logo &&
              (logoPath ? (
                <Link to={logoPath}>
                  <img height={35} src={logo} alt="Logo" />
                </Link>
              ) : (
                <img className={styles.logo} src={logo} alt="Logo" />
              ))}
          </div>
        )}

        <div className={classNames(styles.buttons, { [styles.onMobile]: isMobileMenuOpen })}>
          <SearchBox />

          {buttons.map((button) => {
            const CalloutComponent: React.ComponentType<ICalloutProps> = button.container ?? Callout;

            return (
              <div key={button.key} style={isMobileMenuOpen ? button.mobileStyle : button.style}>
                <HoverTooltip content={button.tooltip} id={button.key}>
                  <div id={`commandBarContainer-${button.key}`}>
                    <CommandBarButton
                      className={classNames(styles.commandButton, { [styles.visible]: isMobileMenuOpen })}
                      key={button.key}
                      iconProps={{ iconName: button.icon }}
                      onClick={onButtonClick(button.key, button.onClick)}
                    />
                  </div>
                </HoverTooltip>
                {button.element && (
                  <CalloutComponent
                    {...baseCalloutProps}
                    style={{ padding: '10px 10px 10px 10px', ...(button.calloutStyle || {}) }}
                    className={button.calloutClassname}
                    target={`#commandBarContainer-${button.key}`}
                    onDismiss={onCalloutDismiss}
                    hidden={!calloutsStates[button.key]}
                    layerProps={{
                      className: button.layerClassName,
                    }}
                  >
                    {button.element(setPopupState(button.key), calloutsStates[button.key])}
                  </CalloutComponent>
                )}
              </div>
            );
          })}
          {!!buttons.length && (
            <CommandBarButton
              className={classNames(styles.commandButton, styles.mobileButton, { [styles.visible]: isMobileMenuOpen })}
              aria-haspopup="true"
              onClick={toggleMobileMenu}
              iconProps={{ iconName: isMobileMenuOpen ? 'ChevronRight' : 'ChevronLeft' }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
